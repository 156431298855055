import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import UniqueEntityID from '../../../core/domain/unique_entity_id';
import {
  GenericException,
  UnAuthorizedException,
} from '../../../core/logic/exception';
import {
  FieldTool,
  FieldType,
} from '../../../ui/template/routes/editor/components/fields-library/field_type';
import { SharedDocumentId } from '../../document/domain/shared_document/shared_document';
import { Survey, SurveyId } from '../domain/survey/survey';
import { SurveyNotFoundException } from '../domain/survey/survey-exceptions';
import { SurveyField } from '../domain/survey/survey-field';
import { TemplateFieldId } from '../domain/templates/template_field';

export interface SurveySchema {
  patientId: string;
  templateId: string;
  patientUserId?: string;
  patientUserWebId?: string;
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  fields?: SurveyFieldSchema[];
  sharedDocumentId: string | undefined;
  sharedAt: Timestamp | undefined;
}

export interface SurveyFieldSchema {
  id: string;
  templateFieldId: string;
  label: string;
  updatedAt: Timestamp;
  type: FieldType;
  params: unknown | null;
  value: unknown | null;
  serieItemId: string | null;
  order: number;
}

@Injectable()
export class SurveyRepository {
  public sendSurveyToSpecificPatient: (data: unknown) => Observable<string>;
  public sendSurveyToGroupedPatients: (data: unknown) => Observable<void>;
  public sendSurveyToPatientSelection: (data: unknown) => Observable<void>;
  public sendDietitianNotificationSurveyUpdated: (
    data: unknown,
  ) => Observable<void>;
  constructor(
    private firestore: AngularFirestore,
    private fireAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private http: HttpClient,
  ) {
    this.sendSurveyToSpecificPatient = this.functions.httpsCallable<
      unknown,
      string
    >('survey-sendSurveyToSpecificPatient');
    this.sendSurveyToGroupedPatients = this.functions.httpsCallable<
      unknown,
      void
    >('survey-sendSurveyToGroupedPatients');
    this.sendSurveyToPatientSelection = this.functions.httpsCallable<
      unknown,
      void
    >('survey-sendSurveyToPatientSelection');
    this.sendDietitianNotificationSurveyUpdated = this.functions.httpsCallable<
      unknown,
      void
    >('survey-sendDietitianNotificationSurveyUpdated');
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<SurveySchema>('surveys', queryFn);
  }

  public toSchema(survey: Survey): SurveySchema {
    return <SurveySchema>{
      patientId: survey.patientId,
      templateId: survey.templateId,
      name: survey.name,
      createdAt:
        survey.createdAt !== undefined
          ? Timestamp.fromDate(survey.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      fields: survey.fields
        .map(
          (f) =>
            <SurveyFieldSchema>{
              id: f.id.toString(),
              templateFieldId: f.templateFieldId.id.toString(),
              label: f.label,
              updatedAt: Timestamp.now(),
              type: f.type,
              params: f.params ?? null,
              value: FieldTool.valueToSchema(f.type, f.value),
              serieItemId: f.serieItemId || null,
              order: f.order,
            },
        )
        .sort((a, b) => a.order - b.order),
      sharedDocumentId: survey.sharedDocumentId?.id.toString() ?? null,
      sharedAt:
        survey.sharedAt !== undefined
          ? Timestamp.fromDate(survey.sharedAt)
          : null,
    };
  }

  public fromSchema(
    schema: SurveySchema,
    dietitianId: string,
    id?: string,
  ): Survey {
    return Survey.create(
      {
        dietitianId: dietitianId,
        patientId: schema.patientId,
        templateId: schema.templateId,
        patientUserId: schema.patientUserId,
        patientUserWebId: schema.patientUserWebId,
        name: schema.name,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        fields:
          schema.fields
            ?.map((f) =>
              SurveyField.create(
                {
                  surveyId: SurveyId.create(new UniqueEntityID(id)),
                  templateFieldId: TemplateFieldId.create(
                    new UniqueEntityID(f.templateFieldId),
                  ),
                  label: f.label,
                  updatedAt: f.updatedAt.toDate(),
                  type: f.type,
                  params: f.params,
                  value: FieldTool.valueFromSchema(f.type, f.value),
                  serieItemId: f.serieItemId ?? undefined,
                  order: f.order,
                },
                new UniqueEntityID(f.id),
              ),
            )
            .sort((a, b) => a.order - b.order) ?? [],
        sharedDocumentId: schema.sharedDocumentId
          ? SharedDocumentId.create(new UniqueEntityID(schema.sharedDocumentId))
          : undefined,
        sharedAt: schema.sharedAt?.toDate() ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async load(dietitianId: string, surveyId: string): Promise<Survey> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(surveyId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new SurveyNotFoundException();
    }

    return this.fromSchema(snap.data() as SurveySchema, dietitianId, snap.id);
  }

  async existsForDietitianIdTemplateId(
    dietitianId: string,
    templateId: string,
  ): Promise<boolean> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('templateId', '==', templateId).limit(1),
      ).get(),
    );

    return snap.size > 0;
  }

  async findByDietitianId(dietitianId: string, asc = false): Promise<Survey[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.orderBy('createdAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async findByDietitianAndPatientId(
    dietitianId: string,
    patientId: string,
    asc = false,
  ): Promise<Survey[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref
          .where('patientId', '==', patientId)
          .orderBy('createdAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async create(survey: Survey): Promise<Survey> {
    const schema = this.toSchema(survey);
    const ref = await this.collection(survey.dietitianId).add(schema);

    return this.fromSchema(schema, survey.dietitianId, ref.id);
  }

  async save(survey: Survey): Promise<Survey> {
    const schema = this.toSchema(survey);
    return this.collection(survey.dietitianId)
      .doc(survey.surveyId.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(
          schema,
          survey.dietitianId,
          survey.surveyId.id.toString(),
        ),
      );
  }

  delete(dietitianId: string, surveyId: string): Promise<void> {
    return this.collection(dietitianId).doc(surveyId).delete();
  }

  async generatePdf(surveyId: string): Promise<Blob> {
    const currentUser = await this.fireAuth.currentUser;
    const token = currentUser ? await currentUser.getIdToken() : undefined;
    if (!token) {
      throw new UnAuthorizedException();
    }

    let result: HttpResponse<Blob>;
    try {
      result = await firstValueFrom(
        this.http.post(
          environment.firebaseFunctionsUrl + 'survey-generateSurveyPdf',
          {
            surveyId: surveyId,
          },
          {
            headers: new HttpHeaders({
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: 'application/pdf, application/json',
            }),
            observe: 'response',
            responseType: 'blob',
          },
        ),
      );
    } catch (error: unknown) {
      if (
        error instanceof HttpErrorResponse &&
        error.error instanceof Blob &&
        error.error.type == 'application/json'
      ) {
        const json = JSON.parse(await error.error.text());
        throw new GenericException(json.message);
      }
      throw new GenericException(error);
    }

    if (!result.ok) {
      throw new GenericException(result.statusText);
    }

    if (!result.body) {
      throw new GenericException();
    }

    if (result.body.type == 'application/pdf') {
      return result.body;
    }

    if (result.body.type == 'application/json') {
      const json = JSON.parse(await result.body.text());
      throw new GenericException(json.message);
    }

    throw new GenericException();
  }
}
