import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { OrderByDirection, Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { DietitianId } from '../domain/dietitian';
import { Nutrition } from '../domain/nutritions/nutrition';
import { NutritionNotFoundException } from '../domain/nutritions/nutrition-exceptions';
import { NutritionQuantityUnit } from '../domain/nutritions/nutrition-quantity-unit';
import {
  NutritionGoalRepository,
  NutritionGoalSchema,
} from './nutrition-goal-repository';
import {
  NutritionMealRepository,
  NutritionMealSchema,
} from './nutrition-meal-repository';
import { NutritionQuantityUnitRepository } from './nutrition-quantity-unit-repository';

export interface NutritionSchema {
  name: string;
  description: string | null;
  targetedEnergyIntake: number;
  proteins: number;
  lipids: number;
  glucids: number;
  fibers: number | null;
  goals?: NutritionGoalSchema[];
  meals?: NutritionMealSchema[];
  header?: string | null;
  sign?: string | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  archivedAt: Timestamp | null;
  authorId?: string | null;
  sourceId?: string | null;
  authorName?: string | null;
}

@Injectable()
export class NutritionRepository {
  constructor(
    private firestore: AngularFirestore,
    private nutritionGoalRepository: NutritionGoalRepository,
    private nutritionMealRepository: NutritionMealRepository,
    private nutritionQuantityUnitRepository: NutritionQuantityUnitRepository,
  ) {
    // NaDa
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<NutritionSchema>('nutritions', queryFn);
  }

  toSchema(nutrition: Nutrition): NutritionSchema {
    return <NutritionSchema>{
      name: nutrition.name,
      description: nutrition.description ?? null,
      targetedEnergyIntake: nutrition.targetedEnergyIntake,
      proteins: nutrition.proteins,
      lipids: nutrition.lipids,
      glucids: nutrition.glucids,
      fibers: nutrition.fibers ?? null,
      goals: nutrition.goals
        ? nutrition.goals.map((g) => this.nutritionGoalRepository.toSchema(g))
        : null,
      meals: nutrition.meals
        ? nutrition.meals.map((m) => this.nutritionMealRepository.toSchema(m))
        : null,
      header: nutrition.header ?? '',
      sign: nutrition.sign ?? '',
      createdAt:
        nutrition.createdAt !== undefined
          ? Timestamp.fromDate(nutrition.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      archivedAt:
        nutrition.archivedAt !== undefined
          ? Timestamp.fromDate(nutrition.archivedAt)
          : null,
      authorId: nutrition.authorId ?? null,
      sourceId: nutrition.sourceId ?? null,
      authorName: nutrition.authorName ?? null,
    };
  }

  fromSchema(
    schema: NutritionSchema,
    dietitianId: string | undefined,
    id: string,
  ): Nutrition {
    return Nutrition.create(
      {
        dietitianId: dietitianId
          ? DietitianId.create(new UniqueEntityID(dietitianId))
          : undefined,
        name: schema.name,
        description: schema.description ?? undefined,
        targetedEnergyIntake: schema.targetedEnergyIntake,
        proteins: schema.proteins,
        lipids: schema.lipids,
        glucids: schema.glucids,
        fibers: schema.fibers ?? undefined,
        goals: schema.goals
          ? schema.goals.map((g) => this.nutritionGoalRepository.fromSchema(g))
          : [],
        meals: schema.meals
          ? schema.meals.map((m) => this.nutritionMealRepository.fromSchema(m))
          : [],
        header: schema.header ?? '',
        sign: schema.sign ?? '',
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
        archivedAt: schema.archivedAt?.toDate(),
        authorId: schema.authorId ?? undefined,
        sourceId: schema.sourceId ?? undefined,
        authorName: schema.authorName ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  async load(dietitianId: string, nutritionId: string): Promise<Nutrition> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(nutritionId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new NutritionNotFoundException();
    }
    const schema = snap.data() as NutritionSchema;
    return this.fromSchema(schema, dietitianId, snap.id);
  }

  async existsForDietitianIdAndName(
    dietitianId: string,
    name: string,
    nutritionId?: string,
  ): Promise<boolean> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('name', '==', name),
      ).get(),
    );
    return snap.size !== 0 && snap.docs.some((doc) => doc.id !== nutritionId);
  }

  async findActiveNutritionsByDietitianId(
    dietitianId: string,
  ): Promise<Nutrition[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref.where('archivedAt', '==', null),
      ).get(),
    );

    const result: Nutrition[] = [];
    for (const doc of snap.docs) {
      result.push(this.fromSchema(doc.data(), dietitianId, doc.id));
    }
    return Promise.resolve(result);
  }

  async findActiveNutritionsByDietitianIdFilteredAndSorted(
    dietitianId: string,
    orderBy: string,
    direction: string,
  ): Promise<Nutrition[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref
          .where('archivedAt', '==', null)
          .orderBy(orderBy, direction as OrderByDirection),
      ).get(),
    );

    const result: Nutrition[] = [];
    for (const doc of snap.docs) {
      result.push(this.fromSchema(doc.data(), dietitianId, doc.id));
    }
    return Promise.resolve(result);
  }

  async findArchivedNutritionsByDietitianIdFilteredAndSorted(
    dietitianId: string,
    orderBy: string,
    direction: string,
  ): Promise<Nutrition[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) =>
        ref
          .where('archivedAt', '!=', null)
          .orderBy('archivedAt', 'asc')
          .orderBy(orderBy, direction as OrderByDirection),
      ).get(),
    );

    const result: Nutrition[] = [];
    for (const doc of snap.docs) {
      const schema = doc.data() as NutritionSchema;
      result.push(this.fromSchema(schema, dietitianId, doc.id));
    }
    return Promise.resolve(result);
  }

  async create(nutrition: Nutrition): Promise<Nutrition> {
    const schema = this.toSchema(nutrition);
    if (nutrition.dietitianId) {
      const ref = await this.collection(
        nutrition.dietitianId.id.toString(),
      ).add(schema);
      return this.fromSchema(
        schema,
        nutrition.dietitianId.id.toString(),
        ref.id,
      );
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async save(nutrition: Nutrition): Promise<Nutrition> {
    const schema = this.toSchema(nutrition);
    if (nutrition.dietitianId) {
      const dietitianId = nutrition.dietitianId.id.toString();
      await this.collection(dietitianId)
        .doc(nutrition.nutritionId.id.toString())
        .set(schema);
      return this.fromSchema(
        schema,
        dietitianId,
        nutrition.nutritionId.id.toString(),
      );
    } else {
      return Promise.reject('Diététicien non identifié');
    }
  }

  async createQuantityUnit(
    quantityUnit: NutritionQuantityUnit,
  ): Promise<NutritionQuantityUnit> {
    return this.nutritionQuantityUnitRepository.create(quantityUnit);
  }

  async updateQuantityUnit(
    quantityUnit: NutritionQuantityUnit,
  ): Promise<NutritionQuantityUnit> {
    return this.nutritionQuantityUnitRepository.save(quantityUnit);
  }

  async deleteQuantityUnit(
    dietitianId: string,
    quantityUnitId: string,
  ): Promise<void> {
    return this.nutritionQuantityUnitRepository.delete(
      dietitianId,
      quantityUnitId,
    );
  }

  async findAllQuantityUnit(
    dietitianId: string,
  ): Promise<NutritionQuantityUnit[]> {
    return this.nutritionQuantityUnitRepository.findAll(dietitianId);
  }
}
