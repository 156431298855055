import { Injectable } from '@angular/core';

import PaginationResult from '../../../../core/domain/pagination-result';
import { ThirdPartyRepository } from '../../repositories/third-party.repository';
import { ThirdPartyEventProvider } from '../events/third-party/third-party-event-provider';
import {
  ThirdPartyCreated,
  ThirdPartyDeleted,
  ThirdPartyUpdated,
} from '../events/third-party/third-party-events';
import { ThirdParty } from './third-party';

@Injectable()
export class ThirdPartyCommands {
  constructor(
    private repository: ThirdPartyRepository,
    private thirdPartyEventProvider: ThirdPartyEventProvider,
  ) {
    // nada
  }

  public async getThirdParty(
    dietitianId: string,
    thirdPartyId: string,
  ): Promise<ThirdParty> {
    return this.repository.load(dietitianId, thirdPartyId);
  }

  public async findAllThirdParty(dietitianId: string): Promise<ThirdParty[]> {
    return this.repository.findByDietitianId(dietitianId);
  }

  public async create(entity: ThirdParty): Promise<ThirdParty> {
    const inserted = await this.repository.create(entity);
    this.thirdPartyEventProvider.dispatch(
      new ThirdPartyCreated(inserted, true),
    );
    return inserted;
  }

  public async update(entity: ThirdParty): Promise<ThirdParty> {
    const updated = await this.repository.save(entity);
    this.thirdPartyEventProvider.dispatch(new ThirdPartyUpdated(updated, true));
    return updated;
  }

  public async delete(entity: ThirdParty) {
    await this.repository.delete(
      entity.dietitianId,
      entity.thirdPartyId.id.toString(),
    );
    this.thirdPartyEventProvider.dispatch(new ThirdPartyDeleted(entity, true));
  }

  async searchDietitianThirdParty(
    dietId: string,
    query: string,
    resultsPerPage: number,
    page: number,
  ): Promise<PaginationResult<ThirdParty>> {
    return this.repository.searchDietitianThirdParty(
      dietId,
      query,
      resultsPerPage,
      page,
    );
  }
}
