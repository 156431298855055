import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';

export enum TypeThirdParty {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
}

export interface ThirdPartyProps {
  dietitianId: string;
  type: TypeThirdParty | string | undefined;
  name: string;
  firstName?: string | undefined;
  email?: string | undefined;
  phoneNumber?: string | undefined;
  web?: string | undefined;
  address?: string | undefined;
  postalCode?: string | undefined;
  city?: string | undefined;
  country?: string | undefined;
  createdAt?: Date;
  updatedAt?: Date;
}

export class ThirdPartyId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): ThirdPartyId {
    return new ThirdPartyId(id);
  }
}

export class ThirdParty extends Entity<ThirdPartyProps> {
  get thirdPartyId(): ThirdPartyId {
    return ThirdPartyId.create(this.id);
  }

  get dietitianId(): string {
    return this.props.dietitianId;
  }

  set dietitianId(value: string) {
    this.props.dietitianId = value;
  }

  get type(): TypeThirdParty | string | undefined {
    return this.props.type;
  }

  set type(value: TypeThirdParty | string | undefined) {
    this.props.type = value;
  }

  get name(): string {
    return this.props.name;
  }

  set name(value: string) {
    this.props.name = value;
  }

  get firstName(): string | undefined {
    return this.props.firstName;
  }

  set firstName(value: string | undefined) {
    this.props.firstName = value;
  }

  get email(): string | undefined {
    return this.props.email;
  }

  set email(value: string | undefined) {
    this.props.email = value;
  }

  get phoneNumber(): string | undefined {
    return this.props.phoneNumber;
  }

  set phoneNumber(value: string | undefined) {
    this.props.phoneNumber = value;
  }

  get web(): string | undefined {
    return this.props.web;
  }

  set web(value: string | undefined) {
    this.props.web = value;
  }

  get address(): string | undefined {
    return this.props.address;
  }

  set address(value: string | undefined) {
    this.props.address = value;
  }

  get postalCode(): string | undefined {
    return this.props.postalCode;
  }

  set postalCode(value: string | undefined) {
    this.props.postalCode = value;
  }

  get city(): string | undefined {
    return this.props.city;
  }

  set city(value: string | undefined) {
    this.props.city = value;
  }

  get country(): string | undefined {
    return this.props.country;
  }

  set country(value: string | undefined) {
    this.props.country = value;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  private constructor(props: ThirdPartyProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: ThirdPartyProps): ThirdParty {
    return ThirdParty.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: ThirdPartyProps,
    id?: UniqueEntityID,
  ): ThirdParty {
    return new ThirdParty(props, id);
  }
}
