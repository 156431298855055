import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';

import PaginationResult from '../../../core/domain/pagination-result';
import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { ThirdParty, TypeThirdParty } from '../domain/third_party/third-party';
import { ThirdPartyNotFoundException } from '../domain/third_party/third-party-exceptions';

export interface ThirdPartySchema {
  type: TypeThirdParty;
  name: string;
  firstName: string | null;
  email: string | null;
  phoneNumber: string | null;
  web: string | null;
  address: string | null;
  postalCode: string | null;
  city: string | null;
  country: string | null;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

@Injectable()
export class ThirdPartyRepository {
  private readonly searchDietitianThirdPartyFromCloud: (
    data: unknown,
  ) => Observable<PaginationResult<{ [key: string]: unknown }>>;

  private static readonly THIRD_PARTY_INDEX = 'third_party';

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    this.searchDietitianThirdPartyFromCloud = this.functions.httpsCallable<
      unknown,
      PaginationResult<{ [key: string]: unknown }>
    >('third_party-searchDietitianThirdParty');
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection<ThirdPartySchema>('third_party', queryFn);
  }

  public toSchema(entity: ThirdParty): ThirdPartySchema {
    return <ThirdPartySchema>{
      type: entity.type,
      name: entity.name,
      firstName: entity.firstName ?? null,
      email: entity.email ?? null,
      phoneNumber: entity.phoneNumber ?? null,
      web: entity.web ?? null,
      address: entity.address ?? null,
      postalCode: entity.postalCode ?? null,
      city: entity.city ?? null,
      country: entity.country ?? null,
      createdAt:
        entity.createdAt !== undefined
          ? Timestamp.fromDate(entity.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
  }

  public fromSchema(
    schema: ThirdPartySchema,
    dietitianId: string,
    id?: string,
  ): ThirdParty {
    return ThirdParty.create(
      {
        dietitianId: dietitianId,
        type: schema.type,
        name: schema.name,
        firstName: schema.firstName ?? undefined,
        email: schema.email ?? undefined,
        phoneNumber: schema.phoneNumber ?? undefined,
        web: schema.web ?? undefined,
        address: schema.address ?? undefined,
        postalCode: schema.postalCode ?? undefined,
        city: schema.city ?? undefined,
        country: schema.country ?? undefined,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
      },
      new UniqueEntityID(id),
    );
  }

  async load(dietitianId: string, thirdPartyId: string): Promise<ThirdParty> {
    const snap = await firstValueFrom(
      this.collection(dietitianId).doc(thirdPartyId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new ThirdPartyNotFoundException();
    }
    return this.fromSchema(
      snap.data() as ThirdPartySchema,
      dietitianId,
      snap.id,
    );
  }

  async findByDietitianId(dietitianId: string): Promise<ThirdParty[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) => ref.orderBy('name', 'asc')).get(),
    );
    return snap.docs.map((doc) =>
      this.fromSchema(doc.data(), dietitianId, doc.id),
    );
  }

  async create(entity: ThirdParty): Promise<ThirdParty> {
    const schema = this.toSchema(entity);
    const ref = await this.collection(entity.dietitianId).add(schema);
    return this.fromSchema(schema, entity.dietitianId, ref.id);
  }

  async save(entity: ThirdParty): Promise<ThirdParty> {
    const schema = this.toSchema(entity);
    return this.collection(entity.dietitianId)
      .doc(entity.thirdPartyId.id.toString())
      .update(schema)
      .then(() =>
        this.fromSchema(
          schema,
          entity.dietitianId,
          entity.thirdPartyId.id.toString(),
        ),
      );
  }

  async delete(dietitianId: string, thirdPartyId: string): Promise<void> {
    return this.collection(dietitianId).doc(thirdPartyId).delete();
  }

  async searchDietitianThirdParty(
    dietitianId: string,
    query: string,
    resultsPerPage: number,
    page: number,
  ): Promise<PaginationResult<ThirdParty>> {
    const index = ThirdPartyRepository.THIRD_PARTY_INDEX;
    const results = await firstValueFrom(
      this.searchDietitianThirdPartyFromCloud({
        dietitianId,
        query,
        index,
        resultsPerPage,
        page,
      }),
    );
    return {
      ...results,
      results: results.results.map(this.fromMap),
    };
  }

  private fromMap(map: { [key: string]: unknown }): ThirdParty {
    const getDate = (data: unknown) =>
      typeof data === 'string' ? new Date(data) : undefined;

    return ThirdParty.create(
      {
        type: map['type'] as string,
        name: map['name'] as string,
        firstName: map['firstName'] as string,
        dietitianId: map['dietitianId'] as string,
        email: map['email'] as string,
        web: map['web'] as string,
        updatedAt: getDate(map['updatedAt']),
        createdAt: getDate(map['createdAt']),
        phoneNumber:
          typeof map['phoneNumber'] === 'string'
            ? map['phoneNumber']
            : undefined,
        address: map['address'] as string,
        postalCode: map['postalCode'] as string,
        city: map['city'] as string,
        country: map['country'] as string,
      },
      new UniqueEntityID(map['id'] as string),
    );
  }
}
