import { DomainEvent } from '../../../../../core/domain/events/domain_event';
import { ThirdParty } from '../../third_party/third-party';

export class ThirdPartyEvent extends DomainEvent<ThirdParty> {}

export class ThirdPartyCreated extends ThirdPartyEvent {}

export class ThirdPartyUpdated extends ThirdPartyEvent {}

export class ThirdPartyDeleted extends ThirdPartyEvent {}
