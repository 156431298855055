import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import {
  NutritionQuantityUnit,
  NutritionQuantityUnitProps,
  NutritionUnit,
} from '../domain/nutritions/nutrition-quantity-unit';

export interface NutritionQuantityUnitSchema {
  id: string;
  dietitianId: string;
  name: string;
  value: number;
  unit: NutritionUnit;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
}

const monoGrammeId = new UniqueEntityID('MONOGRAMME');
const monoGramme = NutritionQuantityUnit.create(
  {
    name: 'Gramme',
    unit: NutritionUnit.G,
    value: 1,
  } as NutritionQuantityUnitProps,
  monoGrammeId,
);

@Injectable()
export class NutritionQuantityUnitRepository {
  private cache = new Map<string, NutritionQuantityUnit>();

  constructor(private firestore: AngularFirestore) {
    // NaDa
  }

  private collection(dietitianId: string, queryFn?: QueryFn) {
    return this.firestore
      .collection('dietitians')
      .doc(dietitianId)
      .collection('nutrition_quantity_units', queryFn);
  }

  toSchema(
    nutritionQuantityUnit: NutritionQuantityUnit,
  ): NutritionQuantityUnitSchema {
    return <NutritionQuantityUnitSchema>{
      id: nutritionQuantityUnit.id.toString(),
      dietitianId: nutritionQuantityUnit.dietitianId ?? null,
      name: nutritionQuantityUnit.name,
      value: nutritionQuantityUnit.value,
      unit: nutritionQuantityUnit.unit,
      createdAt:
        nutritionQuantityUnit.createdAt !== undefined
          ? Timestamp.fromDate(nutritionQuantityUnit.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
  }

  fromSchema(
    schema: NutritionQuantityUnitSchema,
    id?: string | undefined,
  ): NutritionQuantityUnit {
    return NutritionQuantityUnit.create(
      {
        dietitianId: schema.dietitianId,
        name: schema.name,
        value: schema.value,
        unit: schema.unit,
        createdAt: schema.createdAt?.toDate(),
        updatedAt: schema.updatedAt?.toDate(),
      } as NutritionQuantityUnitProps,
      new UniqueEntityID(id),
    );
  }

  async loadAllInCache(dietitianId: string, force = false): Promise<void> {
    if (this.cache.size === 0 || !force) {
      const result = await this.findAll(dietitianId);
      result.forEach((value) => this.cache.set(value.id.toString(), value));
    }
  }

  async findAll(dietitianId: string): Promise<NutritionQuantityUnit[]> {
    const snap = await firstValueFrom(
      this.collection(dietitianId, (ref) => ref.orderBy('name')).get(),
    );
    const units = snap.docs.map((doc) =>
      this.fromSchema(doc.data() as NutritionQuantityUnitSchema, doc.id),
    );
    units.unshift(monoGramme);
    return units;
  }

  async create(
    nutritionQuantityUnit: NutritionQuantityUnit,
  ): Promise<NutritionQuantityUnit> {
    if (nutritionQuantityUnit.dietitianId) {
      const schema = this.toSchema(nutritionQuantityUnit);
      const ref = await this.collection(nutritionQuantityUnit.dietitianId).add(
        schema,
      );
      return this.fromSchema(schema, ref.id);
    } else {
      throw new Error('Dietitian unreachable');
    }
  }

  async save(
    nutritionQuantityUnit: NutritionQuantityUnit,
  ): Promise<NutritionQuantityUnit> {
    if (nutritionQuantityUnit.dietitianId) {
      const dietId = nutritionQuantityUnit.dietitianId;
      const schema = this.toSchema(nutritionQuantityUnit);
      return this.collection(dietId)
        .doc(nutritionQuantityUnit.nutritionQuantityUnitId.id.toString())
        .set(schema)
        .then(() =>
          this.fromSchema(schema, nutritionQuantityUnit.id.toString()),
        );
    } else {
      throw new Error('Dietitian unreachable');
    }
  }

  async delete(
    dietitianId: string,
    nutritionQuantityUnitId: string,
  ): Promise<void> {
    return this.collection(dietitianId).doc(nutritionQuantityUnitId).delete();
  }
}
