import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { LoadingStatus } from '../../../core/domain/events/state_provider';
import { Dietitian } from '../../dietitian/domain/dietitian';
import { MsdpLinkResponse } from '../domain/msdp-link-response';
import { MsdpLoginRequester } from '../domain/msdp-login-requester';
import { MsdpLoginResponse } from '../domain/msdp-login-response';
import { MsdpBasicSuccess } from '../domain/msdp-response';
import { MsdpSignupRequester } from '../domain/msdp-signup-requester';
import { MsdpToken } from '../domain/msdp-token';
import {
  MsdpTokenState,
  MsdpTokenStateProvider,
} from '../domain/msdp-token-state.provider';

@Injectable()
export class MsdpGateway {
  private state: MsdpTokenState;

  constructor(
    private httpClient: HttpClient,
    private msdpTokenStateProvider: MsdpTokenStateProvider,
  ) {
    msdpTokenStateProvider.state$.subscribe((event) => (this.state = event));
  }

  public async login(dietitian: Dietitian): Promise<string | undefined> {
    if (dietitian.msdpUser && dietitian.msdpPassword && dietitian.msdpKey) {
      const offsetUtc = new Date().getTimezoneOffset() / 60;
      const requester: MsdpLoginRequester = {
        user: dietitian.msdpUser,
        pwd: dietitian.msdpPassword,
        msd_id: dietitian.id.toString(),
        offset_utc: offsetUtc,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        msd_email: dietitian.email,
        msd_key: dietitian.msdpKey,
      };
      try {
        const success = await firstValueFrom<
          MsdpBasicSuccess<MsdpLoginResponse>
        >(
          this.httpClient.post<MsdpBasicSuccess<MsdpLoginResponse>>(
            environment.monSuiviDietPlus.urlApi + '/login',
            requester,
          ),
        );
        this.msdpTokenStateProvider.setState({
          data: MsdpToken.create({
            token: success.response.token,
            userId: success.response.user_id,
          }),
          loading: LoadingStatus.COMPLETE,
        });
        return Promise.resolve(success.response.user_id);
      } catch (e: any) {
        return Promise.reject(e);
      }
    } else {
      return Promise.resolve(undefined);
    }
  }

  public async signup(dietitian: Dietitian): Promise<string> {
    if (
      dietitian.msdpUser &&
      dietitian.msdpPassword &&
      dietitian.msdpKey &&
      dietitian.adeliNumber
    ) {
      const offsetUtc = new Date().getTimezoneOffset() / 60;
      const requester: MsdpSignupRequester = {
        firstname: dietitian.firstName,
        lastname: dietitian.lastName,
        phone_number: MsdpGateway.formatPhoneNumber(dietitian.phoneNumber),
        user: dietitian.msdpUser,
        pwd: dietitian.msdpPassword,
        msd_id: dietitian.id.toString(),
        offset_utc: offsetUtc,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        msd_email: dietitian.email,
        msd_key: dietitian.msdpKey,
        adeli_number: dietitian.adeliNumber,
      };
      const success = await firstValueFrom<MsdpBasicSuccess<MsdpLoginResponse>>(
        this.httpClient.post<MsdpBasicSuccess<MsdpLoginResponse>>(
          environment.monSuiviDietPlus.urlApi + '/signup',
          requester,
        ),
      );
      this.msdpTokenStateProvider.setState({
        data: MsdpToken.create({
          token: success.response.token,
          userId: success.response.user_id,
        }),
        loading: LoadingStatus.COMPLETE,
      });

      return Promise.resolve(success.response.user_id);
    } else {
      return Promise.reject(
        "L'utilisateur et mot de passe MonSuiviDiet Plus sont nécessaire, ainsi que le N° Adeli.",
      );
    }
  }

  public async logout(dietitian: Dietitian): Promise<void> {
    if (this.state?.data?.token) {
      try {
        await firstValueFrom<MsdpBasicSuccess<void>>(
          this.httpClient.post<MsdpBasicSuccess<void>>(
            environment.monSuiviDietPlus.urlApi + '/logout',
            { user_id: dietitian.msdpId },
            { headers: { Authorization: 'Bearer ' + this.state?.data?.token } },
          ),
        );
      } catch (e) {
        this.msdpTokenStateProvider.setState({
          data: MsdpToken.create({
            token: undefined,
            userId: undefined,
          }),
          loading: LoadingStatus.COMPLETE,
        });
      }
    }
    this.msdpTokenStateProvider.setState({
      data: MsdpToken.create({
        token: undefined,
        userId: undefined,
      }),
      loading: LoadingStatus.COMPLETE,
    });
  }

  public async link(
    dietitian: Dietitian,
  ): Promise<MsdpBasicSuccess<MsdpLinkResponse>> {
    try {
      return firstValueFrom<MsdpBasicSuccess<MsdpLinkResponse>>(
        this.httpClient.post<MsdpBasicSuccess<MsdpLinkResponse>>(
          environment.monSuiviDietPlus.urlApi + '/link',
          { user_id: dietitian.msdpId },
          { headers: { Authorization: 'Bearer ' + this.state?.data?.token } },
        ),
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async unlink(dietitian: Dietitian): Promise<void> {
    try {
      await firstValueFrom<MsdpBasicSuccess<void>>(
        this.httpClient.post<MsdpBasicSuccess<void>>(
          environment.monSuiviDietPlus.urlApi + '/unlink',
          { user_id: dietitian.msdpId },
          { headers: { Authorization: 'Bearer ' + this.state?.data?.token } },
        ),
      );
      this.msdpTokenStateProvider.setState({
        data: MsdpToken.create({
          token: undefined,
          userId: undefined,
        }),
        loading: LoadingStatus.COMPLETE,
      });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  private static formatPhoneNumber(value: string) {
    value = MsdpGateway.basicPhoneNumber(value);
    if (value.startsWith('+')) {
      value = value.substring(1);
    }
    if (value.startsWith('0')) {
      value = '33' + value;
    }
    return Number(value);
  }

  private static basicPhoneNumber(phoneNumber: string): string {
    return phoneNumber
      .replace(/\s/gi, '')
      .replace(/-/gi, '')
      .replace(/\./gi, '')
      .replace(/[()]/gi, '')
      .replace('*31#', '');
  }
}
