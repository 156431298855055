import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { LoadingStatus } from 'src/app/core/domain/events/state_provider';

import UniqueEntityID from '../../../core/domain/unique_entity_id';
import { SortListType } from '../../../ui/dashboard/routes/questions/questions.service';
import { UserId } from '../../auth/domain/user';
import { DietitianId } from '../../dietitian/domain/dietitian';
import { InvitationTemplate } from '../../dietitian/domain/invitation_template/invitation-template';
import { Email } from '../../email/domain/email/email';
import { EmailCommands } from '../../email/domain/email/email_commands';
import { Template } from '../../email/domain/email/template';
import { Tracker, TrackerProps } from '../../tracker/domain/tracker/tracker';
import {
  TrackerCommands,
  TrackersWrapper,
} from '../../tracker/domain/tracker/tracker_commands';
import { PatientRepository } from '../repositories/patient_repository';
import {
  PatientActivated,
  PatientArchived,
  PatientCreated,
  PatientUpdated,
} from './events/patient.events';
import { PatientEventProvider } from './events/patient_event_provider';
import {
  PatientSelectionActivated,
  PatientSelectionArchived,
} from './events/patient_selection_event';
import { PatientSelectionEventProvider } from './events/patient_selection_event_provider';
import { Patient, PatientId, PatientProps } from './patient';
import {
  PatientExistingNumberException,
  PatientNotFoundException,
} from './patient_exceptions';
import { PatientSerie } from './patient_serie/patient_serie';
import { PatientSerieCommands } from './patient_serie/patient_serie_commands';
import { PatientSerieItem } from './patient_serie/patient_serie_item';
import { PatientState, PatientStateProvider } from './patient_state_provider';

@Injectable()
export class PatientCommands {
  constructor(
    private repository: PatientRepository,
    private stateProvider: PatientStateProvider,
    private eventProvider: PatientEventProvider,
    private selectionEventProvider: PatientSelectionEventProvider,
    private serieCommands: PatientSerieCommands,
    private trackerCommands: TrackerCommands,
    private emailCommands: EmailCommands,
    private toastr: ToastrService,
  ) {
    eventProvider.events$.subscribe((event) => {
      if (!event.notify) return;

      if (event instanceof PatientCreated) {
        if (event.entity) {
          this.toastr.success('Patient créé avec succès!', 'Succès');
        } else {
          this.toastr.error(
            'Une erreur est survenue lors de la création du patient.',
            'Erreur',
          );
        }
      } else if (event instanceof PatientUpdated) {
        this.toastr.success('Patient mis à jour');
      } else if (event instanceof PatientArchived) {
        this.toastr.success('Patient archivé');
      } else if (event instanceof PatientActivated) {
        this.toastr.success('Patient activé');
      }
    });
  }

  async updatePatient(
    patientId: string,
    patientProps: PatientProps,
    notify?: boolean,
  ): Promise<Patient> {
    this.stateProvider.setState(<PatientState>{
      loading: LoadingStatus.LOADING,
      entity: this.stateProvider.state.entity,
    });
    let patient: Patient | undefined = undefined;
    try {
      const oldPatient = await this.setPatientState(patientId);
      if (oldPatient === undefined) {
        throw new PatientNotFoundException();
      }
      patient = oldPatient.copyWith(patientProps);

      if (!oldPatient.archived && patient.archived) {
        this.eventProvider.dispatch(new PatientArchived(patient));
        // remove patient from group when archived
        patient = patient.updateGroupId();
      }
      await this.update(patient, true, notify);

      if (oldPatient.archived && !patient.archived) {
        await this.update(patient, true, notify);
        this.eventProvider.dispatch(new PatientActivated(patient));
      }

      return patient;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<PatientState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: patient,
        });
      }
      throw error;
    }
  }

  getPatient(patientId: string): Promise<Patient> {
    return this.repository.load(patientId);
  }

  async setPatientState(patientId: string): Promise<Patient> {
    this.stateProvider.setState(<PatientState>{
      loading: LoadingStatus.LOADING,
    });
    try {
      const patient = await this.getPatient(patientId);
      this.stateProvider.setState(<PatientState>{
        loading: LoadingStatus.COMPLETE,
        entity: patient,
      });
      return patient;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<PatientState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
        });
      }
      throw error;
    }
  }

  async update(
    patient: Patient,
    waitForSync = true,
    notify = true,
  ): Promise<Patient> {
    this.stateProvider.setState(<PatientState>{
      loading: LoadingStatus.LOADING,
      entity: this.stateProvider.state.entity,
    });
    try {
      if (patient.phoneNumber) {
        // check phone number
        const exists = await this.repository.existsForDietitianAndPhoneNumber(
          patient.dietId.toString(),
          patient.phoneNumber,
          patient.patientId.id.toString(),
        );

        if (exists) {
          throw new PatientExistingNumberException(patient.phoneNumber);
        }
      }

      await this.repository.save(patient, waitForSync);
      this.stateProvider.setState(<PatientState>{
        loading: LoadingStatus.COMPLETE,
        entity: patient,
      });

      this.eventProvider.dispatch(new PatientUpdated(patient, notify));
      return patient;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<PatientState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
          entity: patient,
        });
      }
      throw error;
    }
  }

  async create(
    patientProps: PatientProps,
    waitForSync = true,
  ): Promise<Patient> {
    patientProps.createdAt = new Date();

    this.stateProvider.setState(<PatientState>{
      loading: LoadingStatus.LOADING,
    });

    try {
      if (patientProps.phoneNumber) {
        // check phone number
        const exists = await this.repository.existsForDietitianAndPhoneNumber(
          patientProps.dietId.toString(),
          patientProps.phoneNumber,
        );
        if (exists) {
          throw new PatientExistingNumberException(patientProps.phoneNumber);
        }
      }

      const patient = await this.repository.create(
        Patient.create(patientProps),
        waitForSync,
      );

      this.stateProvider.setState(<PatientState>{
        loading: LoadingStatus.COMPLETE,
        entity: patient,
      });
      this.eventProvider.dispatch(new PatientCreated(patient));

      return patient;
    } catch (error) {
      if (error instanceof Error) {
        this.stateProvider.setState(<PatientState>{
          loading: LoadingStatus.ERROR,
          message: error.message,
        });
      }
      throw error;
    }
  }

  public async getActualWeight(patientId: string) {
    const serie = await this.serieCommands.getLastSerieItem(
      patientId,
      PatientSerie.Weight,
    );

    return serie?.value;
  }

  public async getActualSize(patientId: string) {
    const serie = await this.serieCommands.getLastSerieItem(
      patientId,
      PatientSerie.Size,
    );

    return serie?.value;
  }

  public async setSerieItem(
    patientId: PatientId,
    patientUserId: UserId | undefined,
    timestamp: Date,
    value: number | undefined,
    serie: PatientSerie,
  ) {
    if (value) {
      await this.serieCommands.saveSerieItem({
        patientId,
        patientUserId,
        value,
        serie,
        timestamp,
      });
    }
  }

  searchDietitianPatients(
    dietId: string,
    query: string,
    filterType: SortListType,
    groupId: string | null,
    resultsPerPage: number,
    page: number,
    sort: 'createdAt' | 'updatedAt' | 'lastActiveDate',
  ) {
    return this.repository.searchDietitianPatients(
      dietId,
      query,
      filterType == SortListType.Group ? groupId : null,
      filterType == SortListType.Archived,
      resultsPerPage,
      page,
      sort,
    );
  }

  getDietitianPatients(
    dietitianId: string,
    groupId: string | null,
    includeArchived = false,
  ): Promise<Patient[]> {
    return this.repository.getDietitianPatients(
      dietitianId,
      groupId,
      includeArchived,
    );
  }

  getDietitianPatientsWithUnreadDiaries(
    dietitianId: string,
    groupId: string | null,
    archived: boolean,
  ): Promise<Patient[]> {
    return this.repository.getDietitianPatientsWithUnreadDiaries(
      dietitianId,
      groupId,
      archived,
      30 * 86400,
    );
  }

  async markPatientDiariesAsRead(patientId: string): Promise<Patient> {
    const patient = await this.getPatient(patientId);
    return this.update(patient?.markDiariesAsRead(), false, true);
  }

  async markDietitianPatientsDiariesAsRead(
    dietitianId: string,
    groupId: string | null,
    archived: boolean,
  ): Promise<void> {
    // list patients
    const patients =
      await this.repository.getDietitianPatientsWithUnreadDiaries(
        dietitianId,
        groupId,
        archived,
        0,
      );

    // update patients
    const operations: Promise<unknown>[] = [];
    for (const patient of patients) {
      operations.push(this.update(patient.markDiariesAsRead(), false, false));
    }
    await Promise.all(operations);

    this.toastr.success('Journaux des patients marqués comme lus');
  }

  getPatientGroupPatients(
    dietitianId: string,
    groupId: string,
    includeArchived = true,
  ): Promise<Patient[]> {
    return this.repository.getPatientGroupPatients(
      dietitianId,
      groupId,
      includeArchived,
    );
  }

  searchPatientsForPatientGroup(
    dietitianId: string,
    groupId: string,
    includeArchived: boolean,
    query: string,
    resultsPerPage: number,
    page: number,
  ) {
    return this.repository.searchPatientsForPatientGroup(
      dietitianId,
      groupId,
      includeArchived,
      query,
      resultsPerPage,
      page,
    );
  }

  public async sendInvitationEmailToPatient(
    dietitianId: string,
    patient: Patient | undefined,
    invitationTemplate: InvitationTemplate,
  ): Promise<Email | undefined> {
    if (!patient?.email) {
      this.toastr.error(
        'Vous devez saisir un email pour envoyer une invitation',
      );
      return;
    }

    await this.repository.createPatientInvitationNotification(patient);
    await this.repository.update(
      patient.id.toString(),
      {
        updatedAt: new Date(),
      } as PatientProps,
      true,
    ); // update patient update time

    return this.emailCommands.sendEmail({
      dietitianId,
      patientId: patient.id.toString(),
      to: [patient.email],
      template: Template.create({
        name: 'patient_application_invitation_v2',
        data: {
          preview: null,
          googlePlayLink:
            'https://play.google.com/store/apps/details?id=com.apparence.dietapp',
          appStoreLink:
            'https://apps.apple.com/fr/app/monsuividiet/id1463398409',
          phoneNumber: patient.phoneNumber ?? null,
          contentHtml: invitationTemplate.content.replace(/\r?\n/g, '<br />'),
          contentText: invitationTemplate.content,
        },
      }),
    });
  }

  public async registerPatientForSurvey(data: {
    dietitianId: string;
    surveyId: string;
    conversationId: string | undefined;
  }): Promise<string | undefined> {
    return firstValueFrom<string | undefined>(
      this.repository.registerPatientForSurvey(data),
    );
  }

  public async loginPatientForSurvey(data: {
    dietitianId: string;
    surveyId: string;
    code: string;
  }): Promise<string | undefined> {
    return firstValueFrom<string | undefined>(
      this.repository.loginPatientForSurvey(data),
    );
  }

  public async getSerieWeightAndSizeItems(
    patientId: string,
    mode: 'weight' | 'size' | 'weightAndSize',
  ): Promise<PatientSerieItem[] | undefined> {
    return this.serieCommands.getSerieWeightAndSizeItems(patientId, mode);
  }

  async updatePatientSerieItem(
    serie: PatientSerieItem,
  ): Promise<PatientSerieItem> {
    return this.serieCommands.updatePatientSerieItem(serie);
  }

  async updateTrackersByGroup(patient: Patient) {
    if (patient.groupId && patient.groupId.length > 0) {
      await this.trackerCommands.deleteAllTrackersOfPatient(patient);
      let trackers: TrackersWrapper | undefined;
      for (const groupId of patient.groupId) {
        if (!trackers) {
          trackers = await this.trackerCommands.getTrackers(
            patient.dietId,
            groupId,
            undefined,
          );
        } else {
          const newTrackers = await this.trackerCommands.getTrackers(
            patient.dietId,
            groupId,
            undefined,
          );
          for (const tracker of newTrackers.trackers) {
            const targets = trackers.trackers.filter(
              (t) =>
                t.name === tracker.name &&
                t.diaryType === tracker.diaryType &&
                t.type === tracker.type,
            );
            if (targets) {
              if (targets.length === 1) {
                if (tracker.activatedAt) {
                  targets[0].activatedAt = new Date();
                }
              } else if (targets.length === 0) {
                trackers.trackers.push(tracker);
              }
            }
          }
        }
      }
      if (trackers) {
        await this.trackerCommands.createBatch(
          trackers.trackers.map((t) =>
            Tracker.create({
              ...t.props,
              dietitianId: DietitianId.create(
                new UniqueEntityID(patient.dietId),
              ),
              patientGroupId: patient.groupId,
              patientId: patient.patientId,
              patientUserId: patient.userId,
            } as TrackerProps),
          ),
        );
      }
    }
  }

  async archiveAllPatients(patients: Patient[] | undefined) {
    const cnt = new Map<string, number>();
    if (patients) {
      for (const patient of patients) {
        patient.archivedAt = new Date();
        if (patient.groupId) {
          for (const groupId of patient.groupId) {
            cnt.set(groupId, (cnt.get(groupId) ?? 0) + 1);
          }
        }
        patient.groupId = [];
      }
      await this.repository.updateBatch(patients);
      this.selectionEventProvider.dispatch(
        new PatientSelectionArchived(patients),
      );
    }
    return cnt;
  }

  async activeAllPatients(patients: Patient[] | undefined) {
    if (patients) {
      for (const patient of patients) {
        patient.archivedAt = undefined;
      }
      await this.repository.updateBatch(patients);
      this.selectionEventProvider.dispatch(
        new PatientSelectionActivated(patients),
      );
    }
  }

  async getAllArchivedPatients(dietitianId: string): Promise<Patient[]> {
    return this.repository.getDietitianArchivedPatients(dietitianId);
  }
}
