import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { firstValueFrom, Observable } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { UserId } from '../../auth/domain/user';
import { DietitianId } from '../../dietitian/domain/dietitian';
import { PatientGroup } from '../../dietitian/domain/patient_group/patient_group';
import { Patient, PatientId } from '../../patient/domain/patient';
import {
  Conversation,
  ConversationProps,
} from '../domain/conversation/conversation';
import { Profile, ProfileProps } from '../domain/profile/profile';

export interface ConversationSchema {
  subject: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  dietitian: DietitianConversationSchema;
  patient?: PatientConversationSchema | null;
  closed?: boolean;
}

export interface DietitianConversationSchema {
  firstName: string;
  lastName: string;
  hasNewMessage: boolean;
  id: string;
  userId: string;
  profilePicture?: string;
  lastMessageReadDate?: Timestamp;
}

export interface PatientConversationSchema {
  firstName: string;
  lastName: string;
  id: string;
  userId: string;
  hasNewMessage: boolean;
  profilePicture?: string;
  lastMessageReadDate?: Timestamp;
}

@Injectable()
export class ConversationRepository {
  private readonly markDietitianConversationsAsReadFromCloud: () => Observable<void>;
  private readonly sendQuestionsToGroupedPatientsFromCloud: (
    data: unknown,
  ) => Observable<void>;
  private readonly sendQuestionsToPatientSelectionFromCloud: (
    data: unknown,
  ) => Observable<void>;

  constructor(
    private firestore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    this.markDietitianConversationsAsReadFromCloud =
      this.functions.httpsCallable<void>(
        'conversation-markDietitianConversationsAsRead',
      );

    this.sendQuestionsToGroupedPatientsFromCloud = this.functions.httpsCallable<
      unknown,
      void
    >('conversation-sendQuestionsToGroupedPatients');

    this.sendQuestionsToPatientSelectionFromCloud =
      this.functions.httpsCallable<unknown, void>(
        'conversation-sendQuestionsToPatientSelection',
      );
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<ConversationSchema>(
      'conversations',
      queryFn,
    );
  }

  public toSchema(conversation: Conversation): ConversationSchema {
    return <ConversationSchema>{
      subject: conversation.subject,
      closed: conversation.closed ?? false,
      dietitian: {
        firstName: conversation.dietitian.firstName,
        lastName: conversation.dietitian.lastName,
        hasNewMessage: conversation.dietitian.hasNewMessage,
        id: conversation.dietitian.profileId.id.toString(),
        userId: conversation.dietitian.userId?.id?.toString() ?? null,
        profilePicture: conversation.dietitian.profilePicture ?? null,
        lastMessageReadDate:
          conversation.dietitian.lastMessageReadDate !== undefined
            ? Timestamp.fromDate(conversation.dietitian.lastMessageReadDate)
            : null,
      } as DietitianConversationSchema,
      patient: conversation.patient
        ? ({
            firstName: conversation.patient.firstName,
            lastName: conversation.patient.lastName,
            id: conversation.patient.profileId.id.toString(),
            hasNewMessage: conversation.patient.hasNewMessage,
            userId: conversation.patient.userId?.id?.toString() ?? null,
            profilePicture: conversation.patient.profilePicture ?? null,
            lastMessageReadDate:
              conversation.patient.lastMessageReadDate !== undefined
                ? Timestamp.fromDate(conversation.patient.lastMessageReadDate)
                : null,
          } as PatientConversationSchema)
        : null,
      createdAt:
        conversation.createdAt !== undefined
          ? Timestamp.fromDate(conversation.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
    };
  }

  public fromSchema(schema: ConversationSchema, id?: string): Conversation {
    return Conversation.create(
      {
        closed: schema.closed ?? false,
        subject: schema.subject,
        dietitian: Profile.create({
          profileId: DietitianId.create(
            new UniqueEntityID(schema.dietitian.id),
          ),
          hasNewMessage: schema.dietitian.hasNewMessage ?? false,
          firstName: schema.dietitian.firstName,
          lastName: schema.dietitian.lastName,
          profilePicture: schema.dietitian.profilePicture,
          lastMessageReadDate: schema.dietitian.lastMessageReadDate?.toDate(),
          userId: schema.dietitian.userId
            ? UserId.create(new UniqueEntityID(schema.dietitian.userId))
            : null,
        } as ProfileProps),
        patient: schema.patient
          ? Profile.create({
              profileId: PatientId.create(
                new UniqueEntityID(schema.patient.id),
              ),
              hasNewMessage: schema.patient.hasNewMessage ?? false,
              firstName: schema.patient.firstName,
              lastName: schema.patient.lastName,
              profilePicture: schema.patient.profilePicture,
              lastMessageReadDate: schema.patient.lastMessageReadDate?.toDate(),
              userId: schema.patient.userId
                ? UserId.create(new UniqueEntityID(schema.patient.userId))
                : null,
            } as ProfileProps)
          : undefined,
        createdAt: schema.createdAt.toDate(),
        updatedAt: schema.updatedAt.toDate(),
      } as ConversationProps,
      new UniqueEntityID(id),
    );
  }

  async findConversation(
    conversationId: string,
  ): Promise<Conversation | undefined> {
    const snap = await firstValueFrom(
      this.collection().doc(conversationId).get(),
    );
    const data = snap?.data();
    if (!data) {
      return;
    }

    return this.fromSchema(data, snap.id);
  }

  async markDietitianConversationsAsRead(): Promise<void> {
    return firstValueFrom(this.markDietitianConversationsAsReadFromCloud());
  }

  sendMessagesToGroupedPatients(
    subject: string,
    message: string,
    patientGroup: PatientGroup | undefined,
    archived: boolean,
  ): Promise<void> {
    return firstValueFrom(
      this.sendQuestionsToGroupedPatientsFromCloud({
        patientGroupId:
          patientGroup?.patientGroupId?.id?.toString() ?? undefined,
        subject,
        message,
        archived,
      }),
    );
  }

  async sendMessagesToPatientSelection(
    subject: string,
    message: string,
    patients: Patient[],
  ): Promise<void> {
    const patientIds = patients.map((p) => p.id.toString());
    return firstValueFrom(
      this.sendQuestionsToPatientSelectionFromCloud({
        patientIds,
        subject,
        message,
      }),
    );
  }

  async findByDietitianId(dietitianId: string): Promise<Conversation[]> {
    const limitDate = moment().subtract(1, 'month').toDate();
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitian.userId', '==', dietitianId)
          .where('dietitian.hasNewMessage', '==', true)
          .where('updatedAt', '>=', limitDate)
          .orderBy('updatedAt', 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async findByDietitianIdCount(dietitianId: string): Promise<number> {
    const limitDate = moment().subtract(1, 'month').toDate();
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitian.userId', '==', dietitianId)
          .where('dietitian.hasNewMessage', '==', true)
          .where('updatedAt', '>=', limitDate)
          .orderBy('updatedAt', 'desc'),
      ).get(),
    );

    return snap.size;
  }

  async findByDietitianIdAndPatientId(dietitianId: string, patientId: string) {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitian.userId', '==', dietitianId)
          .where('patient.id', '==', patientId)
          .orderBy('updatedAt', 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async create(conversation: Conversation): Promise<Conversation> {
    const schema = this.toSchema(conversation);
    const ref = await this.collection().add(schema);

    return this.fromSchema(schema, ref.id);
  }

  async save(conversation: Conversation): Promise<Conversation> {
    const schema = this.toSchema(conversation);
    await this.collection()
      .doc(conversation.conversationId.id.toString())
      .set(schema);
    return this.fromSchema(schema, conversation.conversationId.id.toString());
  }

  delete(conversationId: string): Promise<void> {
    return this.collection().doc(conversationId).delete();
  }
}
